import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import GamingLabWebsite from './gaminglabwebsite.png';
import CME from './cme.jpg';
import { AwesomeButton } from 'react-awesome-button';
import { useHistory } from 'react-router-dom';

function CurrentProfession() {
    const history = useHistory();

    return (
        <div style={{ display: "inline-block" }}>
            <div style={{ position: "relative", bottom: "30px" }}>
                <h1 className="toRight" style={{ left: "1%", fontFamily: "Crete Round" }}>Technical Work</h1>
                <Container fluid style={{ position: "relative", float: "left", bottom: "-30px" }}>
                    <Row>
                        <Col md={8} ><p>I currently work as a fullstack engineer at CaptivateIQ. I work on creating new features to help customers calculate sales commissions,
                            working on improving the existing UI and creating new UI features, building new API's, and optimizing the performance of our application. Our tech stack is Django, React, and PostgreSQL.
                        </p>
                            <p>
                                Previously, I worked as a backend software engineer for CME Group.
                                I worked in the Trade Execution division supporting applications that calculate settlement prices for a variety of products/contracts, where
                                we mainly use Java and Spring for our backend framework, Oracle for database. I work closely with our business team to develop new settlement algorithms, support app maintenance in production environment, and deploy builds with new features to enhance user experience and settlement processes.
                            </p></Col>
                        <Col><img src={CME} style={{ width: "100%", height: "auto" }} /></Col>
                    </Row>
                </Container>
            </div>
            <div style={{margin: '20px'}}>
                <AwesomeButton type="secondary" onPress={() => {
                    history.push('/resume');
                }}>Click to see resume!</AwesomeButton>
            </div>
        </div>
    );

}

export default CurrentProfession;

import React from 'react';
import resumePdf from './resume.pdf';
import TypeWriter from './TypeWriter';
import Footer from './Footer';
import SocialMediaIcon from './SocialMediaIcon';



const ResumePage = () => {
    return (
        <div>
            <div>
                <div style={{ display: "flex", paddingTop: "25px"}}>
                    <TypeWriter text="Resume" />
                <div style={{ display: "flex", justifyContent: "flex-end", flex: 1}}>
                    <SocialMediaIcon network="home" url="/" />
                </div>
                </div>
                <div className="fadeIn" style={{backgroundColor: "white", height:"100vh", paddingTop:"20px", paddingBottom: "20px"}}>
                    <iframe src={resumePdf} style={{ width: '100%', height: '100%', }} />
                </div>
            </div>
            <Footer />
        </div>

    );

};

export default ResumePage;
import React from 'react';
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import TypeWriter from './TypeWriter';
import Image from 'react-bootstrap/Image';
import Footer from './Footer';
import logo from "./faith-logo.PNG";
import { useHistory } from "react-router-dom";


const HomePage = () => {
    const history = useHistory();

    const navigateToUrl = (path) => {
        history.push(path);
    };

    const height = (window.screen.height) / 2;
    return (
        <div style={{ position: "relative", bottom: -50 }}>
            <center>
                <img src={logo} style={{ display: "inline-block", width: "30%", height: "30%" }} />
            </center>

            <center>
                <TypeWriter text="Faith Kim" />
                <div>
                    <AwesomeButton type="secondary" onPress={() => navigateToUrl("/about")} style={{ margin: "20px", width: "110px" }}>About</AwesomeButton>
                    <AwesomeButton type="secondary" onPress={() => navigateToUrl("/projects")} style={{ margin: "5px" }} >Projects</AwesomeButton>
                    <AwesomeButton type="secondary" onPress={() => window.location.href = "https://tinyurl.com/apieceofjava"} style={{ margin: "15px" }}> Buy My Book </AwesomeButton>
                </div>
            </center>
            <Footer />
        </div>
    );

}

export default HomePage;